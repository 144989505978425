export const types = {
  CLEAR_ALL_REPORT_DATA: 'CLEAR_ALL_REPORT_DATA',
  SET_SHOW_REPORT_TOOLTIPS: 'SET_SHOW_REPORT_TOOLTIPS',

  // Payments report
  CLEAR_PAYMENTS_REPORT: 'CLEAR_PAYMENTS_REPORT',
  SET_PAYMENTS_REPORT: 'SET_PAYMENTS_REPORT',
  SET_PAYMENTS_REPORT_LOADING: 'SET_PAYMENTS_REPORT_LOADING',

  // Reconciliation report
  CLEAR_RECONCILIATION_REPORT: 'CLEAR_RECONCILIATION_REPORT',
  SET_RECONCILIATION_REPORT: 'SET_RECONCILIATION_REPORT',
  SET_RECONCILIATION_REPORT_LOADING: 'SET_RECONCILIATION_REPORT_LOADING',

  // Payouts report
  CLEAR_PAYOUTS_REPORT: 'CLEAR_PAYOUTS_REPORT',
  SET_PAYOUTS_REPORT: 'SET_PAYOUTS_REPORT',
  SET_PAYOUTS_REPORT_LOADING: 'SET_PAYOUTS_REPORT_LOADING',
};

export const clearAllReportData = () => ({
  type: types.CLEAR_ALL_REPORT_DATA,
});

export const setShowReportTooltips = (data) => ({
  type: types.SET_SHOW_REPORT_TOOLTIPS,
  payload: data,
});

// Payment reports

export const clearPaymentsReport = (data) => ({
  type: types.CLEAR_PAYMENTS_REPORT,
  payload: data,
});

export const setPaymentsReport = (data) => ({
  type: types.SET_PAYMENTS_REPORT,
  payload: data,
});

export const setPaymentsReportLoading = (data) => ({
  type: types.SET_PAYMENTS_REPORT_LOADING,
  payload: data,
});

// Reconciliation report

export const clearReconciliationReport = (data) => ({
  type: types.CLEAR_RECONCILIATION_REPORT,
  payload: data,
});

export const setReconciliationReport = (data) => ({
  type: types.SET_RECONCILIATION_REPORT,
  payload: data,
});

export const setReconciliationReportLoading = (data) => ({
  type: types.SET_RECONCILIATION_REPORT_LOADING,
  payload: data,
});

// Payouts report

export const clearPayoutsReport = (data) => ({
  type: types.CLEAR_PAYOUTS_REPORT,
  payload: data,
});

export const setPayoutsReport = (data) => ({
  type: types.SET_PAYOUTS_REPORT,
  payload: data,
});

export const setPayoutsReportLoading = (data) => ({
  type: types.SET_PAYOUTS_REPORT_LOADING,
  payload: data,
});
