import { useEffect, useState } from 'react';
import styles from '../styles/ErrorPage.module.scss';

import { t } from '../utils/ReactSwitchLangWrapper';
import MainLayout from '../components/MainLayout';
import Button from '../components/Button';
import { useRouter, checkSession } from '../components/Router';
import { events, logAmpEvent } from '../utils/Amplitude';

function Custom404() {
  const router = useRouter();
  const [title, setTitle] = useState(t('PageTitle_Loading'));

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_ERROR_PAGE, {
      Error: '404',
      URL: router.asPath,
    });
    setTitle(t('PageTitle_404'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title={title}>
      <div className={styles.errorPageContainer}>
        <h1>{t('Error404_Title')}</h1>
        <p>{t('Error404_Subtitle')}</p>
        <Button
          text={t('Error404_Btn')}
          ampEvent={events.USER_CLICKED_ERROR_PAGE_BUTTON}
          ampEventProps={{
            Error: '404',
            URL: router.asPath,
          }}
          onClick={() => router.push('/')}
        />
      </div>
    </MainLayout>
  );
}

export default checkSession(Custom404);
