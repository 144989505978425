import { types } from '../actions/PartnerActions';

const initialState = {
  // null partners indicates never been loaded, array indicates a load was made
  partners: null,
  currentPartner: null,
  // only applicable to currentPartner, but we're storing the value outside of
  // currentPartner object since it shouldn't be part of UpdatePartner request body
  voidChequeVerified: false,
};

// eslint-disable-next-line default-param-last
function PartnerReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PARTNERS:
      return { ...state, partners: action.payload };
    case types.SET_CURRENT_PARTNERS:
      return { ...state, currentPartner: action.payload };
    case types.SET_VOID_CHEQUE_VERIFIED:
      return { ...state, voidChequeVerified: action.payload };
    case types.CLEAR_PARTNERS:
    case types.CLEAR_SESSION:
      return initialState;
    default:
      return state;
  }
}

export default PartnerReducer;
