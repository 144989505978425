import { useState, useRef, useEffect } from 'react';
import Image from 'next/legacy/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

import { t, setLanguage, getLanguage } from '../utils/ReactSwitchLangWrapper';
import { ExternalLink } from './Link';
import Button from './Button';
import { useRouter } from './Router';
import { events, logAmpEvent } from '../utils/Amplitude';
import logo from '../public/assets/DirectPayLogo.png';
import badge1 from '../public/assets/footer/CBMCLogo.png';
import badge2 from '../public/assets/footer/100CanadianLogo.png';
import style from '../styles/Footer.module.scss';
import { LANGUAGES } from '../utils/Constants';
import ReplaceTextWithElement, { ItalicizeInterac } from './ReplaceTextWithElement';

function FooterLink({ langKey, href, ariaLabel, className }) {
  return (
    <ExternalLink
      href={href}
      ampEvent={events.USER_CLICKED_FOOTER_LINK}
      ampEventProps={{ Link: t(langKey, null, LANGUAGES.EN.code) }}
      text={t(langKey)}
      aria-label={ariaLabel}
      className={className}
    />
  );
}

export default function Footer({ isLandingPage, showLanguageSwitcher }) {
  const [showLanguageDropdown, toggleLanguageDropdown] = useState(false);
  const langDropdownRef = useRef(null);

  const router = useRouter();
  const ar = router.pathname.split('[lang]');
  const curPath = ar[ar.length - 1];

  useEffect(() => {
    if (!isLandingPage) return undefined;
    const closeOpenMenus = (e) => {
      if (langDropdownRef.current &&
         showLanguageDropdown &&
          !langDropdownRef.current.contains(e.target)) {
        toggleLanguageDropdown(false);
      }
    };
    document.addEventListener('mousedown', closeOpenMenus);

    return () => document.removeEventListener('mousedown', closeOpenMenus);
  }, [isLandingPage, langDropdownRef, showLanguageDropdown]);

  function languageToFull() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.FR.label : LANGUAGES.EN.label;
  }

  function languageToFullButton() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.label : LANGUAGES.FR.label;
  }

  function languageToCode() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.code : LANGUAGES.FR.code;
  }

  function selectLanguage(lang) {
    toggleLanguageDropdown(false);
    if (getLanguage() === lang) return;
    logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === LANGUAGES.FR.code ? 'French' : 'English' });
    document.documentElement.lang = lang;
    if (router.pathname === '/404') {
      setLanguage(lang);
      router.pushStatic('/404', router.asPath);
    } else {
      router.pushStatic(`/${lang}${curPath}`);
    }
  }

  return (
    <footer id="footer" className={style.footer}>
      {isLandingPage ? (
        <div className="maxWidth18">
          <div className={style.linkFooter}>
            <div className={style.column}>
              <div className={style.logo}>
                <Image
                  alt={t('ImageDescription_DirectPayLogo')}
                  src={logo}
                  layout="fixed"
                  width={120}
                  height={50}
                  unoptimized
                />
              </div>
              <p id={style.disclaimer}>{t('Footer_Provinces')}</p>
              <div ref={langDropdownRef} className={style.dropdown}>
                <button
                  type="button"
                  id={style.DropBtn}
                  onClick={() => {
                    logAmpEvent(events.USER_CLICKED_LANGUAGE_DROPDOWN);
                    toggleLanguageDropdown(!showLanguageDropdown);
                  }}
                  data-action="disclosure"
                  aria-controls="account-dropdown"
                  aria-expanded={showLanguageDropdown ? 'true' : 'false'}
                >
                  <FontAwesomeIcon icon={faGlobeAmericas} size="lg" />
                  {languageToFull()}
                  <FontAwesomeIcon icon={faChevronDown} size="1x" />
                </button>
                <div
                  className={`${style.dropContent} ${showLanguageDropdown ? style.show : ''}`}
                  id="account-dropdown"
                >
                  <Button
                    text={LANGUAGES.EN.label}
                    onClick={() => { selectLanguage(LANGUAGES.EN.code); }}
                    ariaLabel={t('Language_Switch_To', null, LANGUAGES.EN.code)}
                    lang={LANGUAGES.EN.code}
                  />
                  <Button
                    text={LANGUAGES.FR.label}
                    onClick={() => { selectLanguage(LANGUAGES.FR.code); }}
                    ariaLabel={t('Language_Switch_To', null, LANGUAGES.FR.code)}
                    lang={LANGUAGES.FR.code}
                  />
                </div>
              </div>
            </div>
            <div className={style.column}>
              <h3>{t('Footer_About')}</h3>
              <FooterLink langKey="Footer_AboutUs" href={t('Link_DirectPay_Partner_Portal_Web_About')} ariaLabel={t('LinkDescription_About')} />
              <FooterLink langKey="Footer_HelpCentre" href={t('Link_DirectPay_Partner_Portal_Web_HelpCentre')} ariaLabel={t('LinkDescription_HelpCentre')} />
            </div>
            <div className={style.column}>
              <h3>{t('Footer_Contact')}</h3>
              <FooterLink href={t('Link_DirectPay_Partner_Portal_Web_SignUp')} langKey="NavBar_SignUp" ariaLabel={t('LinkDescription_SignUp')} />
              <FooterLink href={t('Link_DirectPay_Partner_Portal_Web_RequestDemo')} langKey="Footer_RequestDemo" ariaLabel={t('LinkDescription_RequestDemo')} />
              <FooterLink href={t('Link_DirectPay_Partner_Portal_Web_Contact')} langKey="NavBar_Contact" ariaLabel={t('LinkDescription_Contact')} />
            </div>
            <div className={style.column}>
              <h3>{t('Footer_Legal')}</h3>
              <FooterLink langKey="Footer_PrivacyPolicy" href={t('Link_DirectPay_Partner_Portal_Web_PrivacyPolicy')} ariaLabel={t('LinkDescription_PrivacyPolicy')} />
              <FooterLink langKey="Footer_Accessibility" href={t('Link_DirectPay_Partner_Portal_Web_Accessibility')} ariaLabel={t('LinkDescription_Accessibility')} />
            </div>
            <div className={style.column}>
              <h3>{t('Footer_Connect')}</h3>
              <FooterLink langKey="Footer_Blog" href={t('Link_DirectPay_Partner_Portal_Web_Blog')} ariaLabel={t('LinkDescription_Blog')} />
              <div className={style.connectLinks}>
                <ExternalLink
                  ampEvent={events.USER_CLICKED_FOOTER_LINK}
                  ampEventProps={{
                    Link: t('Footer_Linkedin', null, LANGUAGES.EN.code),
                  }}
                  title={t('Footer_Linkedin')}
                  aria-label={t('LinkDescription_Linkedin')}
                  href={t('Link_DirectPay_Partner_Portal_Web_Linkedin')}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                </ExternalLink>
                <ExternalLink
                  ampEvent={events.USER_CLICKED_FOOTER_LINK}
                  ampEventProps={{
                    Link: t('Footer_Instagram', null, LANGUAGES.EN.code),
                  }}
                  title={t('Footer_Instagram')}
                  aria-label={t('LinkDescription_Instagram')}
                  href={t('Link_DirectPay_Partner_Portal_Web_Instagram')}
                >
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </ExternalLink>
                <ExternalLink
                  ampEvent={events.USER_CLICKED_FOOTER_LINK}
                  ampEventProps={{
                    Link: t('Footer_Facebook', null, LANGUAGES.EN.code),
                  }}
                  title={t('Footer_Facebook')}
                  aria-label={t('LinkDescription_Facebook')}
                  href={t('Link_DirectPay_Partner_Portal_Web_Facebook')}
                >
                  <FontAwesomeIcon icon={faFacebookF} size="lg" />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${getLanguage() === LANGUAGES.FR.code ? style.miniFooterFrench : style.miniFooter}`}>
          <div className={style.footerLinks}>
            <FooterLink langKey="Footer_PrivacyPolicy" href={t('Link_DirectPay_Partner_Portal_Web_PrivacyPolicy')} ariaLabel={t('LinkDescription_PrivacyPolicy')} className={style.miniFooterLink} />
            {showLanguageSwitcher && (
            <Button
              linkBtn
              text={languageToFullButton()}
              ariaLabel={t('Language_Switch_To_Link', null, getLanguage())}
              className={style.miniFooterLink}
              onClick={() => {
                selectLanguage(getLanguage() === LANGUAGES.FR.code ?
                  LANGUAGES.EN.code :
                  LANGUAGES.FR.code);
              }}
              lang={languageToCode()}
            />
            )}
          </div>
          <div>
            <p>
              <ReplaceTextWithElement
                text={t('Footer_SupportText')}
                replaceWith={(
                  <FooterLink
                    langKey="VisitHelpCentre"
                    href={t('Link_DirectPay_Partner_Portal_Web_HelpCentre')}
                  />
                )}
                replaceKey="xVisitHelpCentre"
              />
            </p>
          </div>
        </div>
      )}
      <div id={style.bottom}>
        <div className={style.constFooter}>
          <div className={style.badges}>
            <Image
              alt={t('ImageDescription_BestManagedLogo')}
              src={badge1}
              layout="fixed"
              width={95}
              height={35}
              unoptimized
            />
            <Image
              alt={t('ImageDescription_100CanadianLogo')}
              src={badge2}
              layout="fixed"
              width={100}
              height={55}
              unoptimized
            />
          </div>
          <div className={style.bottomText}>
            <p>{t('Footer_Copyright_Text2')}</p>
            <p><ItalicizeInterac text={t('Footer_Copyright_Text3')} /></p>
          </div>
        </div>
      </div>
    </footer>
  );
}
